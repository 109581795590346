import { Modal, ModalProps } from "antd";
import cn from "classnames";

import style from "./AntModal.module.scss";

const AntModal = (props: ModalProps) => {
  return (
    <Modal
      {...props}
      centered
      className={cn(style.antModal, props.className ? props.className : "")}
    >
      {props.children}
    </Modal>
  );
};

export default AntModal;
