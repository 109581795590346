import { jwtDecode } from "jwt-decode";
import { Navigate, useLocation } from "react-router-dom";

import {
  useLazyGetApiDoctorsGetByPersonIdByPersonIdQuery,
  useLazyGetApiPersonsByIdQuery,
  usePostApiAuthenticationRefreshTokenMutation,
} from "../app/apiRTK";
import { useAppSelector, useAuth } from "../app/hooks";
import { EnumRoles, IDecoded } from "../app/types";
import Preloader from "../components/AntPreloader";
import {
  authAccessState,
  authRefreshState,
  authStatusState,
} from "../storeSlices/login";

export function PrivateOutlet({ children }: { children: any }) {
  // const auth = useAuth();
  const location = useLocation();

  const authState = useAppSelector(authStatusState);
  const access = useAppSelector(authAccessState);
  const refresh = useAppSelector(authRefreshState);

  // useMutation
  const [refreshLogin] = usePostApiAuthenticationRefreshTokenMutation();

  // useLazy
  const [getAuthPerson] = useLazyGetApiPersonsByIdQuery();
  const [getAuthDoctor] = useLazyGetApiDoctorsGetByPersonIdByPersonIdQuery();

  if (!sessionStorage.getItem("firstEntry") && location.pathname !== "/login") {
    sessionStorage.setItem("firstEntry", location.pathname);
  }

  if ((authState === "guest" || authState === "loading") && access && refresh) {
    if (authState === "loading") {
      return (
        <Preloader size="large">
          <div style={{ width: "100wh", height: "100vh" }}></div>
        </Preloader>
      );
    } else {
      refreshLogin({ access, refresh })
        .unwrap()
        .then(({ access }) => {
          if (access) {
            const accessDecoded: IDecoded = jwtDecode(access);
            const personId = accessDecoded.PersonId;
            const roles = accessDecoded.Role;
            if (personId) {
              getAuthPerson(personId);

              if (roles.includes(EnumRoles.Врач)) {
                getAuthDoctor(personId);
              }
            }
          }
        });

      return (
        <Preloader size="large">
          <div style={{ width: "100wh", height: "100vh" }}></div>
        </Preloader>
      );
    }
  }

  if (
    (authState === "guest" || authState === "failedRefresh") &&
    location.pathname !== "/login"
  ) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}
