import { Popover, PopoverProps } from "antd";

import style from "./AntPopover.module.scss";

const AntPopover = (props: PopoverProps) => {
  return (
    <Popover overlayClassName={style.popover} {...props}>
      {props.children}
    </Popover>
  );
};

export default AntPopover;
