import { Form, FormItemProps } from "antd";
import cn from "classnames";

import style from "./AntFormItem.module.scss";

const AntFormItem = (props: FormItemProps) => {
  return (
    <Form.Item {...props} className={cn(style.formItem, props.className)}>
      {props.children}
    </Form.Item>
  );
};

export default AntFormItem;
