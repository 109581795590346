import { apiRTK } from "./apiRTK";
import { store } from "./store";

/**
 *
 *
 * FORM CONSTANTS
 *
 *
 */

// Для выбора часового пояса
export const timezoneOptions = {
  2: { location: "Калининград", gmt: "GMT+2" },
  3: { location: "Москва, Волгоград, Киров", gmt: "GMT+3" },
  4: { location: "Самара, Саратов", gmt: "GMT+4" },
  5: { location: "Екатеринбург", gmt: "GMT+5" },
  6: { location: "Омск", gmt: "GMT+6" },
  7: { location: "Новосибирск, Красноярск, Барнаул", gmt: "GMT+7" },
  8: { location: "Иркутск", gmt: "GMT+8" },
  9: { location: "Чита, Якутск", gmt: "GMT+9" },
  10: { location: "Владивосток", gmt: "GMT+10" },
  11: { location: "Магадан, О-в Сахалин, Среднеколымск", gmt: "GMT+11" },
  12: { location: "Петропавловск-Камчатский, Анадырь", gmt: "GMT+12" },
};

export const VALIDATE_DEBOUNCE_MS = 1000;
export const nameRegExp = /^[\sа-яёА-ЯЁa-zA-Z-]{2,30}$/;
export const positionRegExp =
  /^[а-яёА-ЯЁa-zA-Z](?:[-\s]?[а-яёА-ЯЁa-zA-Z]){1,29}$/;
export const newNameRegExp =
  /^[а-яёА-ЯЁa-zA-Z](?:[-\s]?[а-яёА-ЯЁa-zA-Z]){1,29}$/;
export const passwordRegExp =
  /^(?=[^а-яёА-ЯЁ]*[0-9])(?=[^а-яёА-ЯЁ]*[A-Z])[^а-яёА-ЯЁ]{8,}$/;
export const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const SYST_REG_EXP = /^(9\d|1([0-5]\d|60))$/; // 90-160
export const DIAST_REG_EXP = /^(6\d|[7-9]\d|100)$/; // 60-100
export const PULSE_REG_EXP = /^(4\d|[5-9]\d|100)$/; // 40-100

// systLower: 100
// systUpper: 140
// diastLower: 65
// diastUpper: 90
// pulseLower: 60
// pulseUpper: 90

export const medReferenceData = {
  systLower: 100,
  systUpper: 140,
  diastLower: 65,
  diastUpper: 90,
  pulseLower: 60,
  pulseUpper: 90,
};

export const medRoomReferenceData = {
  ...medReferenceData,
  luminosityLower: 200,
  luminosityUpper: 700,
  temperatureLower: 20,
  temperatureUpper: 35,
  moistureLower: 30,
  moistureUpper: 85,
};

// Этапы измерений для фото
// // Совмещенный этап алкоголя и термометра
// export const stagesPhotoAlcoJoinTemp: Record<number, string> = {
//   0: "Этап авторизации пользователя",
//   1: "Этап измерения давления",
//   2: "Этап измерения уровня этанола в выдыхаемом воздухе и температуры тела",
// };
// раздельный
export const stagesPhoto: Record<number, string> = {
  0: "Этап авторизации пользователя",
  10: "Этап измерения давления",
  20: "Этап измерения уровня этанола и температуры тела",
  21: "Этап измерения уровня этанола",
  22: "Этап измерения температуры тела",
};

// Получаем этап измерения для фото
export const getStagePhoto = (stage: number) => stagesPhoto[stage];

export const getUserNameRules = (userName?: string | null | undefined) => {
  return [
    {
      required: true,
      message: "Обязательно к заполнению",
    },

    {
      message: "Допускается ввод латинских букв, цифр и символа “_”",
      pattern: /^[A-Za-z0-9_]+$/,
    },
    {
      max: 50,
      message: "Ограничение по длине - 50 символов",
    },
    {
      validator: (_: any, value: string) => {
        if (!value?.length) {
          return Promise.resolve();
        }

        if (userName && userName === value) {
          return Promise.resolve();
        }

        return new Promise(async (resolve, reject) => {
          const promise = store.dispatch(
            apiRTK.endpoints.getApiUsersCheckUserNameUniqueByUserName.initiate(
              value,
              { forceRefetch: true }
            )
          );
          const { data: isUnique } = await promise;

          if (isUnique) {
            resolve(1);
          } else {
            reject();
          }
        });
      },
      message: "Пользователь с таким логином уже существует",
    },
  ];
};

export const getPersonalNumberRules = (
  currentPersonalNumber?: string | null | undefined
) => {
  return [
    {
      message: "Табельный номер не может содержать кириллические буквы",
      pattern: /^[^а-яёА-ЯЁ]+$/,
    },
    {
      validator: (_: any, value: string) => {
        if (!value?.length) {
          return Promise.resolve();
        }

        if (currentPersonalNumber && currentPersonalNumber === value) {
          return Promise.resolve();
        }

        return new Promise(async (resolve, reject) => {
          const promise = store.dispatch(
            apiRTK.endpoints.getApiPersonsCheckPersonalNumberUniqueByPersonalNumber.initiate(
              value,
              { forceRefetch: true }
            )
          );
          const { data: isUnique } = await promise;

          if (isUnique) {
            resolve(1);
          } else {
            reject();
          }
        });
      },
      message: "Пользователь с таким персональным номером уже существует",
    },
  ];
};

export const getEmailRules = (isRequired?: boolean) => {
  const rule = [
    {
      required: true,
      message: "Обязательно к заполнению",
    },
    {
      message: "Допускается ввод корректного email адреса",
      pattern: emailRegExp,
    },
    {
      message: "Ограничение по длине - 50 символов",
      max: 50,
    },
  ];

  return isRequired ? rule : rule.slice(1);
};
