import cn from "classnames";

import styleAntButtons from "../AntButton/AntButton.module.scss";

interface IGreenButton {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: "uppercase" | "normal";
}

const GreenButton = (props: IGreenButton) => {
  const { text, onClick, disabled, type = "uppercase" } = props;

  return (
    <button
      className={cn(styleAntButtons.modalBtn, {
        [styleAntButtons.typeUpper]: type === "uppercase",
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{text}</span>
    </button>
  );
};

export default GreenButton;
