import { Avatar, AvatarProps } from "antd";
import cn from "classnames";

import style from "./AntAvatar.module.scss";

const AntAvatar = (props: AvatarProps) => {
  const { className } = props;

  return <Avatar {...props} className={cn(style.avatar, className)} />;
};

export default AntAvatar;
