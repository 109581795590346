import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

import {
  AccessTokenDto,
  DoctorModel,
  PersonModel,
  TokenModel,
  apiRTK,
} from "../../app/apiRTK";
import { RootState } from "../../app/store";
import { EnumRoles, IDecoded, TypeRole } from "../../app/types";

export interface IAuthState extends TokenModel {
  status: "authorized" | "loading" | "failed" | "guest" | "failedRefresh";
  userId: number | null;
  personId: number | null;
  person: PersonModel | null;
  doctor: DoctorModel | null;
  role: EnumRoles[] | null;
  keep: boolean;
}

const initialState: IAuthState = {
  access:
    localStorage.getItem("access") || sessionStorage.getItem("access") || "",
  refresh:
    localStorage.getItem("refresh") || sessionStorage.getItem("refresh") || "",
  status: "guest",
  doctor: null,
  person: null,
  userId: null,
  personId: null,
  role: null,
  keep: true,
};

const refreshFunc = (
  state: Draft<IAuthState>,
  action: PayloadAction<AccessTokenDto>
) => {
  if (action.payload.access) {
    // Декодируем access токен, указывая что мы ожидаем данные в соответствии с IDecoded
    const accessDecoded: IDecoded = jwtDecode(action.payload.access);
    // Вытаскиваем роль
    const role: TypeRole | null = accessDecoded["Role"];

    if (!role || role === EnumRoles.Механик || role === EnumRoles.Обследуемый) {
      state.status = "failed";
    } else {
      state.status = "authorized";
      state.access = action.payload.access;

      if (accessDecoded.PersonId) {
        state.personId = accessDecoded.PersonId;
      }

      state.userId = accessDecoded.UserId;

      if (typeof accessDecoded.Role === "string") {
        state.role = [accessDecoded.Role];
      } else {
        state.role = accessDecoded.Role;
      }

      // Проверяем есть ли сессионное хранилище, если да то пишем в него же
      if (sessionStorage.getItem("access")) {
        sessionStorage.setItem("access", action.payload.access);
      } else if (localStorage.getItem("access")) {
        localStorage.setItem("access", action.payload.access);
      }
    }
  }
};

export const slice = createSlice({
  name: "login",
  initialState,

  reducers: {
    setGuestStatus: (state) => {
      state.status = "guest";
      state.access = "";
      state.refresh = "";
      state.personId = null;
      state.userId = null;
      state.doctor = null;
      state.person = null;
      state.role = null;
      state.keep = true;
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      sessionStorage.removeItem("access");
      sessionStorage.removeItem("refresh");
      localStorage.removeItem("reloadCounter");
    },
    setKeep: (state, action: PayloadAction<boolean>) => {
      state.keep = action.payload;
    },
    // Выполняется во время автоматического рефрештокена в случае истечения сохраненного
    setRefresh: refreshFunc,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        apiRTK.endpoints.postApiAuthenticationLogin.matchPending,
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        apiRTK.endpoints.postApiAuthenticationLogin.matchFulfilled,
        (state, action) => {
          if (action.payload.access) {
            // Декодируем access токен, указывая что мы ожидаем данные в соответствии с IDecoded
            const accessDecoded: IDecoded = jwtDecode(action.payload.access);
            // Вытаскиваем роль
            const role: TypeRole | null = accessDecoded["Role"];

            if (
              !role ||
              role === EnumRoles.Механик ||
              role === EnumRoles.Обследуемый
            ) {
              state.status = "failed";
            } else {
              state.status = "authorized";

              state.access = action.payload.access;
              state.refresh = action.payload.refresh;

              if (accessDecoded.PersonId) {
                state.personId = accessDecoded.PersonId;
              }

              state.userId = accessDecoded.UserId;

              if (typeof accessDecoded.Role === "string") {
                state.role = [accessDecoded.Role];
              } else {
                state.role = accessDecoded.Role;
              }

              if (
                state.keep &&
                action.payload.access &&
                action.payload.refresh
              ) {
                localStorage.setItem("access", action.payload.access);
                localStorage.setItem("refresh", action.payload.refresh);
              } else if (action.payload.access && action.payload.refresh) {
                sessionStorage.setItem("access", action.payload.access);
                sessionStorage.setItem("refresh", action.payload.refresh);
              }
            }
          }
        }
      )
      .addMatcher(
        apiRTK.endpoints.postApiAuthenticationLogin.matchRejected,
        (state) => {
          state.status = "failed";
        }
      )
      .addMatcher(
        apiRTK.endpoints.postApiAuthenticationRefreshToken.matchPending,
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        apiRTK.endpoints.postApiAuthenticationRefreshToken.matchFulfilled,
        refreshFunc
      )
      .addMatcher(
        apiRTK.endpoints.postApiAuthenticationRefreshToken.matchRejected,
        (state) => {
          state.status = "failedRefresh";
        }
      )
      .addMatcher(apiRTK.endpoints.getApiPersonsById.matchPending, (state) => {
        // state.status = "loading";
      })
      .addMatcher(
        apiRTK.endpoints.getApiPersonsById.matchFulfilled,
        (state, action) => {
          // Т.к. редюсер срабатывает всегда после выполнения запроса, то сравниваем айдишники, чтобы не записались чужие данные
          if (state.personId === action.payload.id) {
            state.person = action.payload;
          }
        }
      )
      .addMatcher(apiRTK.endpoints.getApiPersonsById.matchRejected, (state) => {
        // state.status = "failed";
      })
      .addMatcher(
        apiRTK.endpoints.getApiDoctorsGetByPersonIdByPersonId.matchPending,
        (state) => {
          // state.status = "loading";
        }
      )
      .addMatcher(
        apiRTK.endpoints.getApiDoctorsGetByPersonIdByPersonId.matchFulfilled,
        (state, action) => {
          // Т.к. редюсер срабатывает всегда после выполнения запроса, то сравниваем айдишники, чтобы не записались чужие данные
          if (state.personId === action.payload.personId) {
            state.doctor = action.payload;
          }
        }
      )
      .addMatcher(
        apiRTK.endpoints.getApiDoctorsGetByPersonIdByPersonId.matchRejected,
        (state) => {
          // state.status = "failed";
        }
      );
  },
});

export const { setGuestStatus, setKeep, setRefresh } = slice.actions;

export const authStatusState = (state: RootState) => state.login.status;
export const authKeepState = (state: RootState) => state.login.keep;
export const authUserIdState = (state: RootState) => state.login.userId;
export const authPersonIdState = (state: RootState) => state.login.personId;
export const authDoctorState = (state: RootState) => state.login.doctor;
export const authPersonState = (state: RootState) => state.login.person;
export const authAccessState = (state: RootState) => state.login.access;
export const authRefreshState = (state: RootState) => state.login.refresh;
export const authRoleState = (state: RootState) => state.login.role;

const loginReducer = slice.reducer;
export default loginReducer;
