import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";

interface IInitialState {
  isOpenApprovePhotoModal: boolean;
  personIdThatNeedApprovePhoto: number | null;
  isNeedRefetchUserPage: boolean;
  isApproveFromUserPage: boolean;
}

const initialState: IInitialState = {
  isOpenApprovePhotoModal: false,
  personIdThatNeedApprovePhoto: null,
  isNeedRefetchUserPage: false,
  isApproveFromUserPage: false,
};

export const slice = createSlice({
  name: "approvePhotoData",
  initialState,

  reducers: {
    openApprovePhotoModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenApprovePhotoModal = action.payload;
    },
    setApproveFromUserPage: (state, action: PayloadAction<boolean>) => {
      state.isApproveFromUserPage = action.payload;
    },
    setIsNeedRefetchUserPage: (state, action: PayloadAction<boolean>) => {
      state.isNeedRefetchUserPage = action.payload;
    },
    setPersonIdThatNeedApprovePhoto: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.personIdThatNeedApprovePhoto = action.payload;
    },
  },
});

export const {
  openApprovePhotoModal,
  setPersonIdThatNeedApprovePhoto,
  setIsNeedRefetchUserPage,
  setApproveFromUserPage,
} = slice.actions;

export const isOpenApprovePhotoModalState = (state: RootState) =>
  state.approvePhotoData.isOpenApprovePhotoModal;

export const personIdThatNeedApprovePhotoState = (state: RootState) =>
  state.approvePhotoData.personIdThatNeedApprovePhoto;

export const isApproveFromUserPageState = (state: RootState) =>
  state.approvePhotoData.isApproveFromUserPage;

export const isNeedRefetchUserPageState = (state: RootState) =>
  state.approvePhotoData.isNeedRefetchUserPage;

const approvePhotoDataReducer = slice.reducer;
export default approvePhotoDataReducer;
