import { Button } from "antd";
import cn from "classnames";

import style from "./AntButtonGreen.module.scss";
import { AntButtonGreenProps } from "./types";

const AntButtonGreen = (props: AntButtonGreenProps) => {
  const { color, ...restProps } = props;

  const classNames = cn(style.button, props.className, {
    [style.link]: props.type === "link",
    [style.red]: color === "red",
  });

  return (
    <Button size="large" shape="round" {...restProps} className={classNames}>
      {props.children}
    </Button>
  );
};
export default AntButtonGreen;
