export enum ExamTypeEnum {
  Предрейсовый,
  Предсменный,
  Послерейсовый,
  Послесменный,
}

export enum GenderTypeEnum {
  Мужской,
  Женский,
}

export enum EnumRoles {
  Обследуемый = "Обследуемый",
  Руководитель = "Руководитель",
  Врач = "Врач",
  Админ = "Админ",
  Механик = "Механик",
}

export enum EnumEducationType {
  "Специалитет",
  "Интернатура",
  "Ординатура",
  "Аспирантура",
  "Повышение квалификации",
  "Бакалавриат",
  "Магистратура",
}

export interface IDecoded {
  UserId: number;
  PersonId: number | null;
  Role: EnumRoles | EnumRoles[];
  exp: number;
}

export type TypeRole = EnumRoles | EnumRoles[];

export type TypeStringNullUndefined = string | null | undefined;
export type TypeNumberNullUndefined = number | null | undefined;

export type statusType = "pending" | "loading" | "success" | "failure";

export type SortType = "descend" | "ascend" | "default";

export enum NotificationTypeEnum {
  "Обновление ПО",
  "Новое заключение",
  "Новый осмотр",
  "Пропало подключение",
  "Запрос на фото",
  "Фото утверждено",
  "Фото отклонено",
}

export interface INotification {
  id: number;
  title: string;
  description: string;
  time: string;
  examId?: number;
  personId?: number;
  status: "new" | "viewed";
  type: NotificationTypeEnum;
}

export type TypeNewExam = {
  Title: string;
  Body: string;
  ExamId?: number;
  PersonId?: number;
};

export type RangeData = {
  id?: number;
  patientId?: number;
  systLower?: number;
  systUpper?: number;
  diastLower?: number;
  diastUpper?: number;
  pulseLower?: number;
  pulseUpper?: number;
  luminosityLower?: number;
  luminosityUpper?: number;
  temperatureLower?: number;
  temperatureUpper?: number;
  moistureLower?: number;
  moistureUpper?: number;
};
