import { Link } from "react-router-dom";

import { useAppSelector } from "../../../../app/hooks";
import { EnumRoles } from "../../../../app/types";
import { LogoutIcon, UserCircleIcon } from "../../../../assets/icons";
import { authRoleState } from "../../../../storeSlices/login";
import style from "./ProfilePopup.module.scss";

type PopupType = {
  setIsProfilePopup: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfilePopup = (props: PopupType) => {
  const { setIsProfilePopup } = props;

  const authRole = useAppSelector(authRoleState);

  return (
    <div className={style.popup}>
      {authRole &&
        (authRole?.includes(EnumRoles.Руководитель) ||
          authRole?.includes(EnumRoles.Врач)) && (
          <div>
            <Link to="profile" onClick={() => setIsProfilePopup(false)}>
              <UserCircleIcon /> Профиль
            </Link>
          </div>
        )}

      <div>
        <Link to="logout">
          <LogoutIcon /> Выйти из системы
        </Link>
      </div>
    </div>
  );
};

export default ProfilePopup;
