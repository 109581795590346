import { ConfigProvider } from "antd";
import ruRU from "antd/locale/ru_RU";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { store } from "./app/store";
import Preloader from "./components/AntPreloader";
import "./index.scss";
import Login from "./routes/Login";
import Logout from "./routes/Logout";
import Root from "./routes/Root";
import ErrorPage from "./utils/ErrorPage";
import { PrivateOutlet } from "./utils/PrivateOutlet";
import style from "./variables.module.scss";

dayjs.locale("ru");
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

const Dashboard = lazy(
  () => import(/*webpackChunkName:"Dashboard" */ "./routes/Dashboard")
);

const Journal = lazy(
  () => import(/*webpackChunkName:"Journal" */ "./routes/Journal")
);

const Faq = lazy(() => import(/*webpackChunkName:"Faq" */ "./routes/Faq"));

const Notifications = lazy(
  () => import(/*webpackChunkName:"Notifications" */ "./routes/Notifications")
);

const Exam = lazy(() => import(/*webpackChunkName:"Exam" */ "./routes/Exam"));
const Exams = lazy(() => import("./routes/Exams"));
const History = lazy(
  () => import(/*webpackChunkName:"History" */ "./routes/History")
);

const Profile = lazy(
  () => import(/*webpackChunkName:"Profile" */ "./routes/Profile")
);

const User = lazy(() => import(/*webpackChunkName:"User" */ "./routes/User"));
const Users = lazy(
  () => import(/*webpackChunkName:"Users" */ "./routes/Users")
);

const Terminal = lazy(
  () => import(/*webpackChunkName:"Terminals" */ "./routes/Terminal")
);
const Terminals = lazy(
  () => import(/*webpackChunkName:"Terminals" */ "./routes/Terminals")
);

const Organizations = lazy(
  () => import(/*webpackChunkName:"Organizations" */ "./routes/Organizations")
);

const Patient = lazy(
  () => import(/*webpackChunkName:"Patient" */ "./routes/Patient")
);
const Patients = lazy(
  () => import(/*webpackChunkName:"Patients" */ "./routes/Patients")
);

const Reports = lazy(
  () => import(/*webpackChunkName:"Reports" */ "./routes/Reports")
);

const container = document.getElementById("root")!;
const root = createRoot(container);

export const router = createBrowserRouter([
  {
    path: "login",
    element: (
      <PrivateOutlet>
        <Login />
      </PrivateOutlet>
    ),
  },
  { path: "logout", element: <Logout /> },
  {
    path: "/",
    element: (
      <PrivateOutlet>
        <Root />
      </PrivateOutlet>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Preloader />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: "exams",
        element: (
          <Suspense fallback={<Preloader />}>
            <Exams />
          </Suspense>
        ),
      },
      {
        path: "exams/:examId",
        element: (
          <Suspense fallback={<Preloader />}>
            <Exam />
          </Suspense>
        ),
      },
      {
        path: "patients",
        element: (
          <Suspense fallback={<Preloader />}>
            <Patients />
          </Suspense>
        ),
      },
      {
        path: "patients/:personId",
        element: (
          <Suspense fallback={<Preloader />}>
            <Patient />
          </Suspense>
        ),
      },
      {
        path: "journal",
        element: (
          <Suspense fallback={<Preloader />}>
            <Journal />
          </Suspense>
        ),
      },
      {
        path: "journal/:examId",
        element: (
          <Suspense fallback={<Preloader />}>
            <Exam />
          </Suspense>
        ),
      },
      {
        path: "history",
        element: (
          <Suspense fallback={<Preloader />}>
            <History />
          </Suspense>
        ),
      },
      {
        path: "history/:examId",
        element: (
          <Suspense fallback={<Preloader />}>
            <Exam />
          </Suspense>
        ),
      },
      {
        path: "faq",
        element: (
          <Suspense fallback={<Preloader />}>
            <Faq />
          </Suspense>
        ),
      },
      {
        path: "users",
        element: (
          <Suspense fallback={<Preloader />}>
            <Users />
          </Suspense>
        ),
      },
      {
        path: "users/:personId",
        element: (
          <Suspense fallback={<Preloader />}>
            <User />
          </Suspense>
        ),
      },
      {
        path: "terminals",
        element: (
          <Suspense fallback={<Preloader />}>
            <Terminals />
          </Suspense>
        ),
      },
      {
        path: "terminals/:terminalId",
        element: (
          <Suspense fallback={<Preloader />}>
            <Terminal />
          </Suspense>
        ),
      },
      {
        path: "organizations",
        element: (
          <Suspense fallback={<Preloader />}>
            <Organizations />
          </Suspense>
        ),
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={<Preloader />}>
            <Notifications />
          </Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <Suspense fallback={<Preloader />}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: "reports",
        element: (
          <Suspense fallback={<Preloader />}>
            <Reports />
          </Suspense>
        ),
      },
    ],
  },
]);

root.render(
  <Provider store={store}>
    <ConfigProvider
      locale={ruRU}
      theme={{
        token: {
          colorPrimary: style.greenColor,
          colorTextBase: style.mainTextColor,
          colorError: style.colorError,
          fontFamily: style.fontFamily,
        },
        components: {
          Table: {
            borderColor: style.borderColor,
          },
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  </Provider>
);
