import style from "../../variables.module.scss";

// Иконка колокольчика с красным кружком
export const BellHasNotificationIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0384 15.0124C13.3958 16.3461 12.6043 17.7169 11.2707 18.0743C9.93699 18.4317 8.56615 17.6402 8.20879 16.3065M1.71419 9.67788C1.38961 8.51273 1.72715 7.25303 2.59082 6.40627M10.1221 4.78416C10.3756 4.32808 10.4591 3.77683 10.3134 3.233C10.0156 2.12161 8.87322 1.46206 7.76183 1.75986C6.65044 2.05765 5.9909 3.20002 6.28869 4.31141C6.43441 4.85524 6.78235 5.29088 7.22994 5.55911M16.9327 5.60012C16.6312 4.42878 15.709 3.50662 14.5377 3.20513M14.5757 7.87119C14.2782 6.76108 13.4841 5.83278 12.3681 5.2905C11.252 4.74822 9.90541 4.63639 8.62451 4.97961C7.34361 5.32282 6.23334 6.09297 5.53794 7.12063C4.84254 8.14828 4.61898 9.34927 4.91643 10.4594C5.40858 12.2961 5.31301 13.7614 5.00222 14.8749C4.648 16.1439 4.47088 16.7784 4.51873 16.9058C4.57348 17.0516 4.61307 17.0916 4.75827 17.1479C4.88518 17.197 5.41832 17.0542 6.4846 16.7685L16.3722 14.1191C17.4384 13.8334 17.9716 13.6906 18.0569 13.5845C18.1545 13.4632 18.1688 13.4088 18.1433 13.2551C18.1211 13.1209 17.6504 12.6599 16.7091 11.738C15.8832 10.9291 15.0678 9.7079 14.5757 7.87119Z"
      stroke={style.mainTextColor}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="14" width="6" height="6" rx="3" fill="#FF7394" />
  </svg>
);
